/* You can add global styles to this file, and also import other style files */
@import 'assets/layout/styles/layout/layout.scss';

/* PrimeNG */
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/primeng/resources/primeng.min.css';

/* Primeflex  */
@import '../node_modules/primeflex/primeflex.scss';

.layout-light {
  img.hive-logo-dark {
    display: none;
  }
}

.layout-dim {
  img.hive-logo {
    display: none;
  }
}

.layout-dark {
  img.hive-logo {
    display: none;
  }
}

body {
  --font-family: 'Tahoma', 'Verdana', 'Segoe', sans-serif;
  font-family: var(--font-family);
}

body,
html {
  scrollbar-gutter: stable;
}

.app-logo {
  padding: 2rem 2.5rem calc(2rem - 14px) 2.5rem !important;

  .side-nav-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 28px;

    img {
      width: 50%;
    }

    .logo-line {
      height: 1px;
      background-color: #e5e7eb;
      width: 100%;
    }
  }
}

.layout-container {
  min-height: 100vh;
  position: relative;
}

.background-image {
  position: fixed;
  background-attachment: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('../src/assets/images/login.png');
  background-position: center;
  background-size: cover;
  opacity: 0.15;
  z-index: -1;

  height: 100vh;
  width: 100vw;
}

.layout-wrapper {
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 50px;
}

.footer {
  position: absolute;
  bottom: 0;
  right: 3rem;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
  color: #aaa;
}

.text-highlight {
  color: #222;
  background-color: #facc15;
}

// needs to be global because scoped styles are not applied to the badge
.logging-view-preview-container i .p-badge {
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
  font-size: 0.6rem;
}

.w-32rem {
  width: 32rem;
}

.scrollbar-gutter-stable .p-sidebar-content {
  scrollbar-gutter: stable;
}

.p-progress-spinner-svg * {
  stroke: var(--primary-color) !important;
}

a.link {
  color: var(--primary-color);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
  text-underline-offset: 2px;
}

.p-tooltip,
.p-tooltip-top {
  min-width: fit-content;
}

.cut-text {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.chartShown {
  display: block;
}
.chartHidden {
  display: none;
}

.layout-light {
  .logging-view-preview-container {
    color: #1b242866;
  }
}

[data-tippy-root] .tippy-box {
  background-color: #f1f1f1;
  color: #666666;
  border: 1px solid black;
  .tippy-content {
    padding: 0 !important;
  }
  .tippy-header {
    padding: 0.5rem;
    border-radius: 0.2rem;
    background-color: #bababa;
    color: #3b3b3b;
  }
  .tippy-body {
    padding: 0.5rem;
  }
}

.p-tag-secondary {
  background-color: #888;
}
